<template>
    <div>
        <div align="center">
            <h4 class="q-mb-sm">Fatture emesse</h4>
            Estrae tutte le fatture che sono state inviate all'Agenzia delle Entrate e non pi&ugrave; modificabili
            <br><br>
            <hr>
        </div>

        <div class="row justify-center">

            <div class="col-12 col-md-4 q-mr-sm">
                <q-select dense outlined v-model="anno_creazione" :options="options_anno" label="Anno di creazione" />
                <br>
                <q-input dense outlined v-model="nominativo_cliente" label="Nominativo Contraente" />                
            </div>
            <div class="col-12 col-md-4" align="center">
                <q-input dense outlined v-model="numero_pratica" label="Numero pratica" />
                <br>
                <q-btn color="primary" label="Cerca" @click.native="onFatture"/>
            </div>

            <div class="col-12 col-md-12" align="center">
                <hr>
            </div>

        </div>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1000;">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div class="col-12 col-md-12">
            <q-table
                title=""
                :data="righe"
                :columns="colonne"
                color="primary"
                row-key="name"
                separator="cell"
                flat
                wrap-cells
                >

                <template v-slot:header="props">
                    <q-tr :props="props">
                        <q-th
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                            style="font-size: 14px; border-bottom: 1px SOLID #404040; background-color: #ffcc99; color: #000;">
                            <strong>{{ col.label }}</strong>
                        </q-th>
                    </q-tr>
                </template>

                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                        >
                            <strong>{{ col.value }}</strong>
                        </q-td>
                    </q-tr>
                </template>

                <template v-slot:top-right>
                    <q-btn
                    color="primary"
                    icon-right="archive"
                    label="ESPORTA IN CSV"
                    no-caps
                    @click="esportaTabella"
                    />
                </template>
            </q-table>
        </div>

        <div align="center">
            <hr>
            
            <div class="row justify-center">
                <div class="col-md-4" align="center">
                    <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                        @click.native="onIndietroClicked()"
                    />
                </div>
                
            </div>

            <br><br><br><br><br><br>
        </div>

    </div>
</template>

<script>

import { mapFields} from 'vuex-map-fields';
import { mapState,mapActions } from 'vuex';
import { exportFile } from 'quasar';
import QQButton from "@/components/QQButton.vue";

function wrapCsvValue (val, formatFn) {
    let formatted = formatFn !== void 0
        ? formatFn(val)
        : val

    formatted = formatted === void 0 || formatted === null
        ? ''
        : String(formatted)

    formatted = formatted.split('"').join('""')
    /**
     * Excel accepts \n and \r in strings, but some other CSV parsers do not
     * Uncomment the next two lines to escape new lines
     */
    // .split('\n').join('\\n')
    // .split('\r').join('\\r')

    return `"${formatted}"`
}

export default {
    name: "TabellaFattureEmesse",
    components: {
        QQButton
    },
    data() {
        return {
            is_loading: true,
            anno_creazione: "",
            options_anno: [],
            numero_pratica: "",
            nominativo_cliente: ""
        }
    },
    computed: {
        ...mapState({
            //is_loading: state => state.gestionePratiche.is_loading
        }),
        ...mapFields("gestioneFatture", {
            colonne: "colonne",
            righe: "righe",
            idpratica: "idpratica_selezionata",
            idcontratto: "idcontratto_selezionato"
        }),
    },
    methods: {
        ...mapActions({
            ElencoFattureEmesse: "gestioneFatture/ElencoFattureEmesse"
        }),
        onIndietroClicked() {
            this.$router.push({name : "Fatture"});
        },
        esportaTabella() {
            // naive encoding to csv format
            const content = [ this.colonne.map(col => wrapCsvValue(col.label)) ].concat(
                this.righe.map(row => this.colonne.map(col => wrapCsvValue(
                    typeof col.field === 'function'
                        ? col.field(row)
                        : row[col.field === void 0 ? col.name : col.field],
                    col.format
                )).join(','))
            ).join('\r\n')

            const status = exportFile(
                'Fatture_Attesa_Emissione.csv',
                content,
                'text/csv'
            )

            if (status !== true) {
                this.$q.notify({
                    message: 'Browser denied file download...',
                    color: 'negative',
                    icon: 'warning'
                })
            }
        },
    },
    async mounted() {
        this.is_loading = true;
        let oggi = new Date();
        this.anno_creazione = oggi.getFullYear();

        console.log("anno:",this.anno_creazione);

        await this.ElencoFattureEmesse(this.anno_creazione);

        this.options_anno = [
            { "label": this.anno_creazione, "value": this.anno_creazione },
            { "label": parseInt(this.anno_creazione-1), "value": parseInt(this.anno_creazione-1) },
            { "label": parseInt(this.anno_creazione-2), "value": parseInt(this.anno_creazione-2) }
        ];
    }
}
</script>

<style >

   td {
       cursor: pointer
    }

</style>


